.custom-checkbox {
    cursor: pointer;
    font-size: rem(14);
    line-height: (22/15);
    font-family: $font-default;
    font-weight: $font-default-weight;
}
.custom-checkbox + .custom-checkbox {
    margin-top: rem(12);
}
.custom-checkbox label {
    cursor: pointer;
}
.custom-checkbox:before,
.custom-checkbox:after {
    content: " ";
    display: table;
}
.custom-checkbox:after {
     clear: both;
}
.custom-checkbox__input {
    position: absolute;
    width: rem(1);
    height: rem(1);
    margin: rem(-1);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.custom-checkbox__box {
    position: relative;
    float: left;
    margin-right: rem(12);
    border: 1px solid $color-grey;
    background: $color-grey;
    width: rem(22);
    height: rem(22);
    margin-top: rem(-1);
}
.custom-checkbox__box:before {
    position: absolute;
    top: 50%;
    right: rem(-5);
    transform: translateY(-50%);
    font-size: rem(18);
    color: $secondary;
    visibility: hidden;
}
.custom-checkbox__input:checked ~ .custom-checkbox__box:before {
    visibility: visible;
}
.custom-checkbox__input:not(:checked):focus ~ .custom-checkbox__box {
    border-color: $secondary;
}
.has-error .custom-checkbox__box {
    border-color: $danger;
}
.custom-checkbox__text {
    display: block;
    overflow: hidden;
}
.custom-checkbox__text__icon {
    font-size: rem(20);
    vertical-align: -.2em;
    margin-left: rem(2);
}

.custom-checkbox--white .custom-checkbox__box {
    background: #fff;
    border-color: #fff;
    box-shadow: 0 -1px 0 0 $color-grey;
}