/*todo*/
/*.overlay-box:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,.2);
}*/
.overlay-box {
    position: fixed;
    right: 50%;
    margin-right: rem(-1440/2);
    top: 0;
    border-top: rem(5) solid $secondary;
    z-index: 1;
    width: rem(400);
    padding: rem(35) rem(30);
    font-size: rem(15);
    transform: translateY(-100%);
    opacity: 0;
    transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;
}
.overlay-box__close {
    position: absolute;
    right: 0;
    top: 0;
    padding: rem(15);
}

.overlay-box.is-visible {
    transform: translateY(0);
    opacity: 1;
}
.navbar.is-transitioning .overlay-box {
    opacity: 0;
}