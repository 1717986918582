.hero {
    position: relative;
}
.hero .slick-slider {
    overflow: hidden;
}
.hero__slide {
    position: relative;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 58%;
    overflow: hidden;

    @media screen and (min-width: 768px) {
        padding-top: 0;
        min-height: rem(673);
        min-height: (673 / 1680) * 100vw;
    }
}

@media screen and (min-width: 768px) {
    .hero--sm-with-gradient:before,
    .hero--sm-with-gradient-full:before,
    .hero__slide:before {
        content:'';
        position: absolute;
        top:0;
        bottom:0;
        right:0;
        left:0;
        background: radial-gradient(circle, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 18.65%, #000000 100%);
        z-index:1;
        opacity: .6;
    }
    .hero--sm-with-gradient-full:before {
        background:#000;
        opacity: .45;
    }
}
.hero__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: auto;

    @media screen and (min-width: 768px) {
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 100%;
        width: auto;
    }
}
.hero__content {
    position: relative;
    padding: 1rem 0 rem(40);
    background: $color-bg-default;
    z-index:5;

    @media screen and (min-width: 768px) {
        padding: 1rem rem(60);
        background: transparent;
    }
}
.hero__content--singleitem {
    @media screen and (max-width: 767px) {
        padding: 1.5rem 0;
    }
}
.hero__title {
    font-size: rem(32);
    line-height: 1;

    @media screen and (min-width: 768px) {
        font-size: rem(50);
    }

    @media screen and (min-width: 1200px) {
        font-size: rem(72);
    }
}
.hero__text {
    margin-top: rem(15);

    @media screen and (min-width: 768px) {
        font-size: rem(18);
        margin-top: rem(32);
    }
}
.hero__badge {
    font-size: rem(15);
    line-height: (18/15);
    padding: rem(8) rem(18);
}
.hero__scroll{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(50%) rotate(90deg);
    color: #fff;
    font-size: rem(50);
}
.hero__scroll:after {
    content: $icon-hexagon;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: rem(44);
    line-height:1px;
    color: $secondary;
    transition: color 200ms ease-in-out;
}
.hero__scroll__icon {
    position: absolute;
    top: 1.55rem;
    left: rem(3);
    font-size: rem(30);
    line-height:1px;
    color: #fff;
    z-index: 1;
}
.hero__scroll:hover {
    color: #fff;
}
.hero__scroll:hover:after {
    color: $primary;
}

.hero__content--top {
    @media screen and (min-width: 768px) {
        position: absolute;
        left: 0;
        right: 0;
        top: 20%;
    }

    @media screen and (min-width: 1200px) {
        top: 30%;
    }
}

.hero__content--middle {
    @media screen and (min-width: 768px) {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}

.hero__arrow {
    position: absolute;
    bottom: 12%;
    left: 50%;
    transform: translateX(-100%);
    font-size: rem(13);
    color:#fff;
    padding: 0 rem(40);
    z-index:5;
}
.hero__arrow--next {
    transform: translateX(0);
}
.hero__arrow__icon {
    font-size: rem(30);
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}
.hero__arrow--next .hero__arrow__icon {
    left: auto;
    right: 0;
}

/* small hero */
.hero--sm .hero__title {
    font-size: rem(28);
    margin-bottom: rem(2);

    @media screen and (min-width: 768px) {
        font-size: rem(46);
        line-height: (56/46);
    }
}
.hero--sm .hero__text {
    font-size: rem(20);
    margin-top: 0;
}

@media screen and (max-width: 767px) {
    .hero .slick-arrow {
        top: 20%;
    }
    .hero .slick-dots {
        left: 0;
        right: 0;
        transform: none;
        display: flex;
        padding: 0;
        margin-top: 58%;
    }
    .hero .slick-dots li {
        align-items: flex-end;
        display: flex;
        flex: 1 1 100%;
    }
    .hero .slick-dots button {
        width: 100%;
        bottom: rem(5);
    }
}