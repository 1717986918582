.navbar  {
  padding: 0;
  align-items: flex-end;
  z-index: 100;
  background: #fff;
  box-shadow: 0 rem(2) rem(3) rgba(0,0,0,.1);
}
.navbar-brand {
  width: rem(60);
  margin: rem(-25) rem(36) rem(-24) rem(-3);
  z-index: 1;
  padding: 0;

  @media screen and (min-width: 768px){
      width: rem(100);
      margin: rem(-65) rem(25) rem(-65) rem(-3);
  }

  @media screen and (min-width: 1200px){
    width: rem(105);
      margin: rem(-60) rem(36) rem(-56) rem(-3);
  }
}
.navbar-brand > img {
  max-width: 100%;
  height: auto;
}

.navbar-nav .nav-link:hover {
  color: $secondary;
}

/* navbar main */
.navbar-main {
  border-top: rem(1) solid $color-grey;
}
.navbar-main .nav-item {
  @media screen and (min-width: 768px) {
      margin-top: calc(15rem / 16);
  }
}
.navbar-main .navbar-nav {
  @media screen and (min-width: 768px) {
    margin: 0 rem(-18);
  }
}
.navbar-main .nav-item .nav-link {
  font-family: $font-default-bold;
  font-weight: $font-default-weight;
  font-size: calc(16rem / 16);
  line-height: calc(21/18);
  padding: calc(20rem / 16);

  @media screen and (min-width: 768px) {
    padding: calc(10rem / 16) calc(12rem / 16) calc(24rem / 16);
  }
  @media screen and (min-width: 1200px) {
    padding: calc(10rem / 16) calc(18rem / 16) calc(24rem / 16);
      font-size: calc(18rem / 16);
  }
}


/* navbar top */
.navbar-top {
    margin: 0 0 0 rem(-9);
    font-size: rem(13);

    @media screen and (min-width: 1200px) {
        font-size: rem(16);
    }
}
.navbar-top .nav-item {
  padding: rem(8) 0;
}
.navbar-top .nav-item .nav-link {
  font-family: $font-default;
  font-weight: $font-default-weight;
}

/* navbar search */
.navbar-main__search {
    flex-wrap: nowrap;
}
.navbar-main__search .form-control {
  border: none;
  background: transparent;
  font-family: $font-default-bold;
  font-weight: $font-default-bold-weight;
  padding-right: rem(15);
  font-size: rem(16);
  display: inline-block;
  vertical-align: middle;

    @media screen and (min-width: 768px) {
        width: auto;

    }
}
.navbar-main__search__submit__icon {
  font-size: rem(20);
  vertical-align: -0.2em;
}

/* navbar shortcuts*/
li + li .navbar-top__link {
    @media screen and (min-width: 1400px) {
        margin-left: rem(10);
    }
}
.navbar-top__link__icon {
  vertical-align: -.05em;
  margin-right: rem(3);
}
.navbar-top__link__icon-flag {
  margin-right: rem(8);
}


/* affix */
.navbar.is-transitioning {
  position: absolute;
  left: 0;
  right: 0;
}
.navbar.is-affix {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
}