html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
}
body {
    position: relative;
    overflow-x: hidden;
}
body.has-cookie-bar{
    margin-bottom: rem(80);

    @media screen and (max-width: 767px){
        margin-bottom: rem(150);
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    body {
        width: calc(982rem/16);
        overflow-x: scroll;
    }
}
@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
    }
}
.container.container {
    max-width: calc(1470rem/16);
    width: 100%;
}
.container-narrow {
    max-width: rem(1280);
}
.container-sm {
    max-width: rem(1060);
}
.container-xs {
    max-width: rem(960);
    margin: 0 auto;
}