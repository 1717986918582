/*Margin Helper*/

/*Padding Helper*/


/*Text Helper*/
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


/*Text Color Helper*/
.text-primary-hover:hover, a.text-primary {color: $primary-link-color !important;}
a.text-primary:hover {color: darken($primary-link-color, 10%) !important;}
.text-secondary-hover:hover, .text-secondary-hover:focus { color: $secondary; }
.text-white { color: #ffffff; }
.text-color-default { color: $default-text-color; }
.text-orange { color: $color-orange; }
.text-brown { color: $color-brown; }
.text-grey-dark { color: $color-grey-dark; }
.text-gold {color:#C09B57;}

.text-poi-1{color: $primary}
.text-poi-2{color: $color-orange}
.text-poi-3{color: #5ac4be}
.text-poi-4{color: $color-brown}
.text-poi-5{color: #5A3EB9}
.text-poi-6{color: #CC0000}
.text-poi-7{color: #056383}
.text-poi-8{color: #D16B00}
.text-poi-9{color: #5BA7C1}
.text-poi-10{color: #884600}


/*Background Color Helper*/
.bg-default { background: $color-bg-default; color: #fff}
.bg-primary {color: #fff;}
.bg-secondary {color: #fff;}
.bg-gradient {background: linear-gradient(180deg, #EEEEEE 0%, rgba(238,238,238,0) 100%);}
.bg-grey {background: $color-grey;}
.bg-grey-light {background: $color-grey-light;}

.isClickable {cursor: pointer;}

.link-with-icon__icon {
    font-size: rem(40);
    line-height: 1.03;

    @media screen and (min-width: 768px) {
        font-size: rem(50);
    }
}

.content-box{
    padding: rem(15);
    font-size: rem(12);

    @media screen and (min-width: 768px) {
        padding: rem(45) rem(58);
    }
}