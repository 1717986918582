$theme-colors: (
    primary: #0077c1,
    secondary: #5ac4be
);
$primary:   #0077c1;
$secondary: #5ac4be;
$default-text-color: #282828;

//input styles
$input-bg: #F4F4F4;
$input-border-color: #F4F4F4;

//tabs
$nav-tabs-border-width: 0;
$nav-tabs-link-active-color: theme-color('secondary');
$nav-pills-link-active-color: $default-text-color;
$nav-pills-link-active-bg: #DCDCDC;


// Breadcrumbs
$breadcrumb-margin-bottom:          0;

$breadcrumb-bg:                     transparent;
$breadcrumb-divider-color:          #999999;
$breadcrumb-active-color:           #999999;

// Pagination
$pagination-bg:                     transparent;
$pagination-border-width:           0;
$pagination-border-color:           transparent;
$pagination-focus-box-shadow:       none;
$pagination-hover-bg:               transparent;
$pagination-hover-border-color:     $secondary;
$pagination-active-bg:              transparent;
$pagination-active-border-color:    $secondary;
$pagination-disabled-bg:            transparent;

// Modal
$modal-content-border-color:     transparent;
$modal-content-border-width:     0;

//dropdown
$dropdown-border-width:         0;
$dropdown-spacer:               0;