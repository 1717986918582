/* base form styles in boostrap/_custom.scss */
.form-control {
    font-family: $font-default;
    font-weight: $font-default-weight;
    padding: rem(12) rem(10);
    font-size: rem(16);
    line-height: (22/18);

    @media screen and (min-width: 768px){
        padding: rem(14) rem(16);
    }
}
.form-control:focus {
    box-shadow: none;
    color: $secondary;
}

.form-group {
    position: relative;
    margin-bottom: rem(20);
}
.form-group--sm {
    margin-bottom: rem(12);
}
.form-control-btn {
    position: absolute;
    right: 0;
    top: rem(8);
    font-size: rem(18);
    background: #fff;
    padding: 0 rem(2) 0 rem(5);
    
    @media screen and (min-width: 768px) {
        font-size: rem(20);
    }
}

/* form colors */
.form-control--light {
    background: transparent;
    border: 0;
    border-bottom: rem(1) solid #999999;
    padding: rem(10) rem(2);
}
.form-control--white {
    background: #fff;
    box-shadow: 0 rem(-1) 0 0 $color-grey;
    color: $secondary;
}

.required-symbol {
    font-size: rem(14);
    line-height: 1;
    vertical-align: 0.2em;
    color: $primary;
}
.input-wrapper {
    position: relative;
}
.input-wrapper .required-symbol {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background: $color-grey;
    width: rem(16);
    text-align: center;
    padding: rem(5) 0;
}

.has-error .form-control,
.has-error .custom-checkbox__box,
.has-error .custom-radio__box {
    border-color: $color-danger;
}
.has-error .input-wrapper .required-symbol {
    color: #fff;
    background: $color-danger;
}
.has-success .form-control,
.has-success .custom-checkbox__box,
.has-success .custom-radio__box {
    border-color: $color-success;
}
.has-success .input-wrapper .required-symbol {
    color: #fff;
    background: $color-success;
}

.filter-title{
    border-bottom: rem(1) solid $color-grey;
    padding-bottom: rem(2);
}