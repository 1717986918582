.location-item {
    font-size: rem(14);
    line-height: (18/14);
    padding: rem(15);

    @media screen and (min-width: 768px) {
        padding: rem(30);
    }
}
.location-item + .location-item {
    margin-top: rem(5);
}
.location-item__title {
    font-size: rem(14);
    line-height: 1;
    margin-bottom: rem(5);
}
.location-item__address {
    font-size: rem(14);
    line-height: (22/14);
}
.location-item__contact + .location-item__contact {
    margin-top: rem(9);
}
.location-item__contact__icon {
    margin-right: rem(8);
    vertical-align: -0.2em;
}

/* location collapse groups */
.location-item-group__body {
    margin-bottom: rem(25);
}
.location-item-group__title {
    font-size: rem(18);
}
.location-item-group__title > a {
    position: relative;
    padding: rem(20) rem(30) rem(20) 0;
    border-bottom: 2px solid $color-grey;
}
.location-item-group__title__icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: rem(12);
}