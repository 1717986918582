.wysiwyg > p,
.wysiwyg > ol,
.wysiwyg > ul,
.wysiwyg > table {
    margin-bottom: 0.6em;
}
.wysiwyg > p:last-child,
.wysiwyg > ol:last-child,
.wysiwyg > ul:last-child,
.wysiwyg > table:last-child {
    margin-bottom: 0;
}
.wysiwyg img {
    max-width: 100%;
    height: auto !important; /*override inline style from wysiwyg*/
}
.wysiwyg a:not(.btn) {
    color: $primary;
    font-family: $font-default-bold;
    font-weight: $font-default-weight;
}
.wysiwyg a:not(.btn):hover,
.wysiwyg a:not(.btn):focus,
.wysiwyg a:not(.btn):active {
    color: $primary;
    text-decoration: underline;
}
.wysiwyg ol,
.wysiwyg ul {
    padding: 0;
}
.wysiwyg ol li,
.wysiwyg ul li {
    position: relative;
    list-style: none;
    padding-left: rem(28);

    @media screen and (min-width: 768px) {
        padding-left: rem(35);
    }
}
.wysiwyg ul li:before {
    content:'';
    display: block;
    position: absolute;
    left: .625rem;
    top: .375rem;
    width: .5rem;
    height: .625rem;
    background-image:url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDE0IDI1IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zOnNlcmlmPSJodHRwOi8vd3d3LnNlcmlmLmNvbS8iIHN0eWxlPSJmaWxsLXJ1bGU6ZXZlbm9kZDtjbGlwLXJ1bGU6ZXZlbm9kZDtzdHJva2UtbGluZWpvaW46cm91bmQ7c3Ryb2tlLW1pdGVybGltaXQ6MS40MTQyMTsiPgogICAgPGcgdHJhbnNmb3JtPSJtYXRyaXgoMSwwLDAsMSwtNjkyLjEyNSwtMTQ0MS40KSI+CiAgICAgICAgPGcgdHJhbnNmb3JtPSJtYXRyaXgoNC4xNjY2NywwLDAsNC4xNjY2NywwLDApIj4KICAgICAgICAgICAgPHBhdGggZD0iTTE2OC4wMTIsMzQ2LjI1OEMxNjcuODk4LDM0Ni4wNTkgMTY3LjY4NywzNDUuOTM3IDE2Ny40NTgsMzQ1LjkzN0wxNjYuMTEzLDM0NS45MzdMMTY2LjExLDM0NS45NDFMMTY3LjYwMiwzNDguNTM4QzE2Ny43MTYsMzQ4LjczNyAxNjcuNzE2LDM0OC45ODIgMTY3LjYwMiwzNDkuMTgxTDE2Ni4xMSwzNTEuNzc4TDE2Ni4xMTMsMzUxLjc4M0wxNjcuNDU4LDM1MS43ODNDMTY3LjY4NywzNTEuNzgzIDE2Ny44OTgsMzUxLjY2IDE2OC4wMTIsMzUxLjQ2MkwxNjkuMzIyLDM0OS4xODFDMTY5LjQzNiwzNDguOTgyIDE2OS40MzYsMzQ4LjczNyAxNjkuMzIyLDM0OC41MzhMMTY4LjAxMiwzNDYuMjU4WiIgc3R5bGU9ImZpbGw6cmdiKDY0LDYzLDY1KTtmaWxsLXJ1bGU6bm9uemVybzsiLz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPgo=');
    background-repeat: no-repeat;
    background-size: contain;

    @media screen and (min-width: 768px) {
        top: .5rem;
        left: 1rem;
    }
}
.wysiwyg ol li + li,
.wysiwyg ol li ol,
.wysiwyg ul li + li,
.wysiwyg ul li ul {
    margin-top: rem(8);

    @media screen and (min-width: 768px) {
        margin-top: rem(15);
    }
}

.wysiwyg ol { counter-reset: item }
.wysiwyg ol li{ display: block }
.wysiwyg ol li:before {
    position: absolute;
    left: .5rem;
    top: 0;
    content: counters(item, ".") " ";
    counter-increment: item;

    @media screen and (min-width: 768px) {
        left: 1rem;
    }
}

.wysiwyg ol li li:before {
    left: 0rem;
    @media screen and (min-width: 768px) {
        left: .5rem;
    }
}
.wysiwyg ol li li li:before {
    left: -.5rem;
    @media screen and (min-width: 768px) {
        left:0rem;
    }
}

/* wysiwyg text color white */
.text-white .wysiwyg ul li:before {
    background: #fff;
}
.text-white .wysiwyg a:not(.btn) {
    text-decoration: underline;
    color: #fff;
}
.text-white .wysiwyg a:not(.btn):hover,
.text-white .wysiwyg a:not(.btn):focus,
.text-white .wysiwyg a:not(.btn):active {
    color: #fff;
    text-decoration: none;
}