.slide {
    padding-top: rem(30);
    padding-bottom: rem(30);
}
@media screen and (min-width: 768px) {
    .slide {
        padding-top: rem(60);
        padding-bottom: rem(60);
    }
}

.slide-sm {
    padding-top: rem(15);
    padding-bottom: rem(15);
}

.slide__head {
    padding-bottom: rem(30);

    @media screen and (min-width: 768px) {
        padding-bottom: rem(60);
    }
}

.slide__head h2 {
    color:#C09B57;
}

.slide--no-top-padding.slide--no-top-padding {
    padding-top: 0;
}

.slide.slide--with-rgb-flexigon {
    position: relative;
}
@media screen and (min-width: 768px) {
    .slide.slide--with-rgb-flexigon:before {
        content:'';
        pointer-events: none;
        display: block;
        position: absolute;
        left:-5rem;
        bottom:-13rem;
        width:900px;
        height:900px;
        z-index:-1;
        background: url(/static/img/flexigons/flexigon-rgb-square.svg) -90px 100% no-repeat;
    }
}
