/* colors */
$color-default:         #282828;
$color-bg-default:      #3B3B39;
$color-grey:            #DCDCDC;
$color-grey-medium:     #C6C6C6;
$color-grey-light:      #f4f4f4;
$color-grey-dark:       #696969;
$color-orange:          #FF7100;
$color-brown:           #A66E00;
$color-purple:          #5a3eb9;
$color-danger:          #CC0000;
$color-success:         #24b377;
$primary-link-color:       $primary;

/* fonts */
$font-default:          'EncodeSans-Light', Arial, sans-serif !default;
$font-default-bold:     'EncodeSans-Semibold', 'Arial Black', sans-serif !default;
$font-default-italic:   'EncodeSans-Light', Arial, sans-serif !default;
/*$font-fira-mono:        'FiraMono-Regular', sans-serif !default;*/

$font-default-weight:  400;
$font-default-bold-weight:  $font-default-weight;
$font-default-italic-weight:  $font-default-weight;


@function rem($size) {
  $remSize: $size / 16 * 1rem;
  @return #{$remSize};
}