.row.row--gutter-width-0 {
    margin-left:0;
    margin-right:0;
}

.row--gutter-width-0 > .col,
.row--gutter-width-0 > [class^="col"],
.row--gutter-width-0 > [class*=" col"] {
    padding-left:0;
    padding-right:0;
}

.row.row--gutter-width-4 {
    margin-left:rem(-2);
    margin-right:rem(-2);
}
.row--gutter-width-4 > .col,
.row--gutter-width-4 > [class^="col"],
.row--gutter-width-4 > [class*=" col"] {
    padding-left:rem(2);
    padding-right:rem(2);
}

.row.row--gutter-width-12 {
    margin-left:rem(-6);
    margin-right:rem(-6);
}
.row--gutter-width-12 > .col,
.row--gutter-width-12 > [class^="col"],
.row--gutter-width-12 > [class*=" col"] {
    padding-left:rem(6);
    padding-right:rem(6);
}

.row.row--gutter-width-16 {
    margin-left:rem(-8);
    margin-right:rem(-8);
}
.row--gutter-width-16 > .col,
.row--gutter-width-16 > [class^="col"],
.row--gutter-width-16 > [class*=" col"] {
    padding-left:rem(8);
    padding-right:rem(8);
}

.row.row--gutter-width-20 {
    margin-left:-10px;
    margin-right:-10px;
}

.row--gutter-width-20 > .col,
.row--gutter-width-20 > [class^="col"],
.row--gutter-width-20 > [class*=" col"] {
    padding-left:10px;
    padding-right:10px;
}

@media screen and (min-width: 1200px) {
    .row.row--gutter-width-36 {
        margin-left:-18px;
        margin-right:-18px;
    }
    .row--gutter-width-36 > .col,
    .row--gutter-width-36 > [class^="col"],
    .row--gutter-width-36 > [class*=" col"] {
        padding-left:18px;
        padding-right:18px;
    }

    .row.row--gutter-width-60 {
        margin-left:-30px;
        margin-right:-30px;
    }
    .row--gutter-width-60 > .col,
    .row--gutter-width-60 > [class^="col"],
    .row--gutter-width-60 > [class*=" col"] {
        padding-left:30px;
        padding-right:30px;
    }

    .row.row--gutter-width-120 {
        margin-left:-60px;
        margin-right:-60px;
    }
    .row--gutter-width-120 > .col,
    .row--gutter-width-120 > [class^="col"],
    .row--gutter-width-120 > [class*=" col"] {
        padding-left:60px;
        padding-right:60px;
    }
}


/* vertical gutter */
.row--vertical-gutter-4 {
    margin-top: rem(-4);
}
.row--vertical-gutter-4 > .col,
.row--vertical-gutter-4 > [class*='col-'] {
    margin-top: rem(4);
}
.row--vertical-gutter-6 {
    margin-top: rem(-6);
}
.row--vertical-gutter-6 > .col,
.row--vertical-gutter-6 > [class*='col-'] {
    margin-top: rem(6);
}
.row--vertical-gutter-12 {
    margin-top: rem(-12);
}
.row--vertical-gutter-12 > .col,
.row--vertical-gutter-12 > [class*='col-'] {
    margin-top: rem(12);
}
.row--vertical-gutter-24 {
    margin-top: rem(-24);
}
.row--vertical-gutter-24 > .col,
.row--vertical-gutter-24 > [class*='col-'] {
    margin-top: rem(24);
}
.row--vertical-gutter-30 {
    margin-top: rem(-30);
}
.row--vertical-gutter-30 > .col,
.row--vertical-gutter-30 > [class*='col-'] {
    margin-top: rem(30);
}

@media screen and (min-width: 768px) {
    .row--vertical-gutter-70-lg {
        margin-top: rem(-70);
    }
    .row--vertical-gutter-70-lg > .col,
    .row--vertical-gutter-70-lg > [class*='col-'] {
        margin-top: rem(70);
    }
}