.news-teaser {
  font-size: rem(15);
  line-height: (24/15);
}
.news-teaser__head {
  position: relative;
  margin-bottom: rem(12);
}
.news-teaser__head:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  opacity: .6;
  transition: opacity 300ms ease-in-out;
}
.news-teaser:hover .news-teaser__head:after {
  opacity: 0;
}
.news-teaser__head__content {
    z-index: 1;
    padding: rem(15) rem(50);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    @media screen and (max-width: 1199px) {
        padding: rem(5) 0 rem(5) rem(40);
    }
}
.news-teaser__head__content__title {
    font-size: rem(22);
    line-height: (28/22);

    @media (min-width: 768px) and (max-width: 1199px) {
        font-size: rem(18);
    }

    @media screen and (max-width: 767px) {
        font-size: rem(22);
    }

}

.news-teaser__additional-info li {
    display: inline-block;
    position: relative;
}
.news-teaser__additional-info li:not(:last-child){
    padding-right: rem(15);
}
.news-teaser__additional-info li:not(:last-child):before {
    content: '';
    position: absolute;
    right: rem(4);
    top: 50%;
    transform: translateY(-50%);
    width: rem(3);
    height: rem(3);
    border-radius: 50%;
    background: $color-bg-default;
}