@import "./boostrap/bootstrap-inline";
//@import "./libs/lightgallery.min.css";
//@import "slick-carousel/slick/slick.scss";

/* Global variables and settings */
@import './styles/mediaqueries';
@import './styles/variables';

@import './styles/icons';
//@import './styles/flags';


/* General base styling & reset (mostly plain element styling; very low specificity ) */
@import './styles/base';
@import './styles/base-layout';
@import './styles/base-typo';

/* Navbar */
@import './styles/navbar';
@import './styles/navbar-mobile';
@import './styles/breadcrumb';
//@import './styles/scrollspy';

/* Generic helper classes (mostly layout) */
@import './styles/row--gutter';

/* Components */
@import './styles/btn';
@import './styles/custom-checkbox';
//@import './styles/custom-radio';
//@import './styles/select';
//@import './styles/dropdown';
//@import './styles/form-errors';
//@import './styles/form-control-label';
@import './styles/content-block';
@import './styles/slide';
@import './styles/page-heading';
@import './styles/wysiwyg';
//@import './styles/shariff';
@import './styles/hero';
@import './styles/contact-block';
//@import './styles/decorator-item';
@import './styles/form';
//@import './styles/tabs';
//@import './styles/solution-box';
//@import './styles/pre-footer';
//@import './styles/footer';
//@import './styles/pagination';
//@import './styles/text-with-media';
//@import './styles/console';
//@import './styles/language-item';
//@import './styles/modal';
//@import './styles/hotspot';
//@import './styles/filter-form';
//@import './styles/map-infobox';
@import './styles/location-item';
//@import './styles/figure';
//@import './styles/lightbox';
//@import './styles/testimonial';
//@import './styles/hexagon-img';
@import './styles/overlay-box';
//@import './styles/password-strength';
//@import './styles/loading-spinner';

/* slider */
//@import './styles/slider';
//@import './styles/usp-slider';
//@import './styles/teaser-slider';
//@import './styles/thumb-slider';

/* teaser */
//@import './styles/img-teaser';
@import './styles/news-teaser';
//@import './styles/horizontal-teaser';


/* Utility & Helper Classes (mostly atomic classes; high specificity; eg: mt10; fz20)*/
@import './styles/oocss';


.thumb-slider__thumbs__item:nth-child(3) ~ .thumb-slider__thumbs__item,
.thumb-slider__main__item + .thumb-slider__main__item,
.teaser-slider__item + .teaser-slider__item,
.slider_item + .slider_item {
    display: none;
}