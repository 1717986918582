/*Base-Styling for HTML-Elements*/

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
}
th {
    font-family: $font-default-bold;
    font-weight: $font-default-bold-weight;
}
textarea {
    resize: vertical;
}

label {
    font-weight: normal;
    margin-bottom: 0;
}
ul,
ol,
dl {
    margin-bottom: 0;
}
dt {
    font-weight: normal;
}

*::-moz-selection {
    color: #ffffff;
    background-color:  $primary;
}
*::selection {
    color: #ffffff;
    background-color: $primary;
}