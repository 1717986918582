.breadcrumb {
    padding: rem(15) 1rem rem(10) rem(6);

    @media screen and (min-width: 768px) {
        padding: rem(40) 1rem rem(28) rem(6);
    }

    @media screen and (min-width: 1200px) {
        padding: rem(22) 1rem rem(28) rem(6);
    }
}
.breadcrumb-item {
  color: $primary-link-color;
  font-size: rem(14);
  line-height: (16/14);
  position: relative;
}
.breadcrumb-item:not(:last-child) {
    margin-right: rem(10);
}
.breadcrumb-item + .breadcrumb-item {
    padding-left: rem(14);
}
.breadcrumb-item + .breadcrumb-item::before {
  content: '';
  width: rem(3);
  height: rem(3);
  background: #999;
  display: inline-block;
  padding: 0;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}