.btn {
  position: relative;
  font-family: $font-default-bold;
  font-weight: $font-default-weight;
  font-size: rem(16);
  line-height: 1.15;
  padding: rem(14) rem(18) rem(13);
  text-transform: uppercase;

  @media screen and (min-width: 768px) {
    font-size: rem(18);
    padding: rem(16) rem(32) rem(15);
  }
}
.btn-sm {
    display: inline-block;
    padding: .5rem !important;
    font-family: $font-default-bold;
    font-size: rem(15) !important;
    text-transform: uppercase;
}
.btn-secondary {
  margin-right: rem(15);
  color: #fff;
}
.btn-secondary:after {
  content: '';
  position: absolute;
  right: rem(-1);
  bottom: rem(-1);
  top: rem(-1);
  transform: translateX(rem(15));
  width: rem(12);
  background: $primary;
}

.btn-grey-light {
    background: $color-grey-light;
    color: $color-default;
}
.btn-grey-light:hover,
.btn-grey-light:focus {
    background: $color-grey;
}

.btn-no-styling,
.btn.btn-no-styling {
  border: none;
  background: none;
  text-align: left;
  padding: 0;
  color: inherit;
  font-family: inherit;
  font-weight: inherit;
  cursor: pointer;
}

/* button with icon & text */
.btn.btn-with-icon {
  padding-left: rem(60);
}
.btn-with-icon .btn__icon {
  position: absolute;
  left: rem(15);
  top: 50%;
  transform: translateY(-50%);
  font-size: rem(32);
}

/* disabled buttons */
.btn.disabled {
    background-color: #999999;
    border-color: #999999;
}
.btn-secondary.disabled:after {
    background-color: #696969;
}