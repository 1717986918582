@font-face {
    font-family: "iconfont";
    src: url('/static/build/icons/font/iconfont.eot?#iefix') format('eot'),
    url('/static/build/icons/font/iconfont.woff') format('woff'),
    url('/static/build/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}

.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.icon-ul > li {
    position: relative;
}
.icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.icon-li.icon-lg {
    left: -1.85714286em;
}

.icon-rotate-90 {
    transform: rotate(90deg);
}
.icon-rotate-180 {
    transform: rotate(180deg);
}
.icon-rotate-270 {
    transform: rotate(270deg);
}
.icon-flip-horizontal {
    transform: scale(-1, 1);
}
.icon-flip-vertical {
    transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  filter: none;
}

.icon-agenti:before { content: "\EA01" }
.icon-ansicht:before { content: "\EA02" }
.icon-arrow-down:before { content: "\EA03" }
.icon-arrow-left:before { content: "\EA04" }
.icon-arrow-right:before { content: "\EA05" }
.icon-chat:before { content: "\EA06" }
.icon-checkmark:before { content: "\EA07" }
.icon-close:before { content: "\EA08" }
.icon-compass:before { content: "\EA09" }
.icon-download:before { content: "\EA0A" }
.icon-elements:before { content: "\EA0B" }
.icon-expand:before { content: "\EA0C" }
.icon-extranet:before { content: "\EA0D" }
.icon-facebook:before { content: "\EA0E" }
.icon-fax:before { content: "\EA0F" }
.icon-feather:before { content: "\EA10" }
.icon-hexagon:before { content: "\EA11" }
.icon-homepage:before { content: "\EA12" }
.icon-instagram:before { content: "\EA13" }
.icon-linkedin:before { content: "\EA14" }
.icon-listview:before { content: "\EA15" }
.icon-lock:before { content: "\EA16" }
.icon-mail-round:before { content: "\EA17" }
.icon-mail:before { content: "\EA18" }
.icon-map:before { content: "\EA19" }
.icon-minus:before { content: "\EA1A" }
.icon-news:before { content: "\EA1B" }
.icon-open:before { content: "\EA1C" }
.icon-person:before { content: "\EA1D" }
.icon-phone-round:before { content: "\EA1E" }
.icon-phone:before { content: "\EA1F" }
.icon-pinterest:before { content: "\EA20" }
.icon-play:before { content: "\EA21" }
.icon-poi:before { content: "\EA22" }
.icon-quote:before { content: "\EA23" }
.icon-scroll-down:before { content: "\EA24" }
.icon-scroll-right:before { content: "\EA25" }
.icon-search:before { content: "\EA26" }
.icon-technogramm:before { content: "\EA27" }
.icon-twitter:before { content: "\EA28" }
.icon-vk:before { content: "\EA29" }
.icon-world:before { content: "\EA2A" }
.icon-xing:before { content: "\EA2B" }
.icon-youtube:before { content: "\EA2C" }


$icon-agenti: "\EA01";
    $icon-ansicht: "\EA02";
    $icon-arrow-down: "\EA03";
    $icon-arrow-left: "\EA04";
    $icon-arrow-right: "\EA05";
    $icon-chat: "\EA06";
    $icon-checkmark: "\EA07";
    $icon-close: "\EA08";
    $icon-compass: "\EA09";
    $icon-download: "\EA0A";
    $icon-elements: "\EA0B";
    $icon-expand: "\EA0C";
    $icon-extranet: "\EA0D";
    $icon-facebook: "\EA0E";
    $icon-fax: "\EA0F";
    $icon-feather: "\EA10";
    $icon-hexagon: "\EA11";
    $icon-homepage: "\EA12";
    $icon-instagram: "\EA13";
    $icon-linkedin: "\EA14";
    $icon-listview: "\EA15";
    $icon-lock: "\EA16";
    $icon-mail-round: "\EA17";
    $icon-mail: "\EA18";
    $icon-map: "\EA19";
    $icon-minus: "\EA1A";
    $icon-news: "\EA1B";
    $icon-open: "\EA1C";
    $icon-person: "\EA1D";
    $icon-phone-round: "\EA1E";
    $icon-phone: "\EA1F";
    $icon-pinterest: "\EA20";
    $icon-play: "\EA21";
    $icon-poi: "\EA22";
    $icon-quote: "\EA23";
    $icon-scroll-down: "\EA24";
    $icon-scroll-right: "\EA25";
    $icon-search: "\EA26";
    $icon-technogramm: "\EA27";
    $icon-twitter: "\EA28";
    $icon-vk: "\EA29";
    $icon-world: "\EA2A";
    $icon-xing: "\EA2B";
    $icon-youtube: "\EA2C";
    