body {
    color: $default-text-color;
    font-family: $font-default;
    font-size: rem(15);
    line-height: (28/17);

    @media screen and (min-width: 768px) {
        font-size: rem(17);
    }
}

b,
strong,
.strong,
.font-default-bold {
    font-family: $font-default-bold;
    font-weight: 400;
}
.font-default-italic {
    font-family: $font-default-italic;
    font-weight: 400;
}
/*.font-fira-mono {
    font-family: $font-fira-mono;
}*/

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    display: block;
}

h1, .h1 {
    font-size: rem(72);
    line-height: 1;
    font-family: $font-default-bold;
    font-weight: 400;
}
h2, .h2 {
    font-size: rem(30);
    line-height: (56/46);
    font-family: $font-default-bold;
    font-weight: 400;

    @media screen and (min-width: 768px) {
        font-size: rem(46);
    }
}
h3, .h3 {
    font-size: rem(24);
    font-family: $font-default-bold;
    font-weight: 400;

    @media screen and (min-width: 768px) {
        font-size: rem(32);
    }
}
h4, .h4 {
    font-size: rem(18);

    @media screen and (min-width: 768px) {
        font-size: rem(22);
    }
}
h5, .h5 {
    font-size: calc(18rem/16);
}
h6, .h6 {
    font-size: 1em;
}