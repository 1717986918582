@charset "UTF-8";
/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #0077c1;
  --secondary: #5ac4be;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #0077c1;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #004875;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .btn:hover, .btn:focus {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 119, 193, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
  .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
    background-image: none; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #0077c1;
  border-color: #0077c1; }
  .btn-primary:hover {
    color: #fff;
    background-color: #005f9b;
    border-color: #00588e; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 119, 193, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #0077c1;
    border-color: #0077c1; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #00588e;
    border-color: #005081; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 119, 193, 0.5); }

.btn-secondary {
  color: #212529;
  background-color: #5ac4be;
  border-color: #5ac4be; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #41b6b0;
    border-color: #3eada7; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(90, 196, 190, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #212529;
    background-color: #5ac4be;
    border-color: #5ac4be; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #3eada7;
    border-color: #3ba49e; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(90, 196, 190, 0.5); }

.btn-outline-primary {
  color: #0077c1;
  background-color: transparent;
  background-image: none;
  border-color: #0077c1; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #0077c1;
    border-color: #0077c1; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 119, 193, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #0077c1;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #0077c1;
    border-color: #0077c1; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 119, 193, 0.5); }

.btn-outline-secondary {
  color: #5ac4be;
  background-color: transparent;
  background-image: none;
  border-color: #5ac4be; }
  .btn-outline-secondary:hover {
    color: #212529;
    background-color: #5ac4be;
    border-color: #5ac4be; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(90, 196, 190, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #5ac4be;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #212529;
    background-color: #5ac4be;
    border-color: #5ac4be; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(90, 196, 190, 0.5); }

.btn-link {
  font-weight: 400;
  color: #0077c1;
  background-color: transparent; }
  .btn-link:hover {
    color: #004875;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d; }

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0; }

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d; }

.nav-tabs {
  border-bottom: 0 solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: 0; }
  .nav-tabs .nav-link {
    border: 0 solid transparent; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #5ac4be;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: 0; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #282828;
  background-color: #DCDCDC; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .dropdown-menu-right {
      right: 0;
      left: auto; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .dropup .dropdown-menu {
    top: auto;
    bottom: 100%; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 0;
  list-style: none;
  background-color: transparent; }

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #999999;
  content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #999999; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid transparent;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef; }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #0077c1 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #00588e !important; }

.bg-secondary {
  background-color: #5ac4be !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #3eada7 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #0077c1 !important; }

.border-secondary {
  border-color: #5ac4be !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.embed-responsive-2by1::before {
  padding-top: 50%; }

.embed-responsive-technogramm::before {
  padding-top: 93.75%; }

.embed-responsive-8by3::before {
  padding-top: 37.5%; }

.embed-responsive-7by3::before {
  padding-top: 42.85714%; }

@media screen and (max-width: 767px) {
  .embed-responsive-5by6-xs::before {
    padding-top: 120%; }
  .embed-responsive-8by5-xs::before {
    padding-top: 62.5%; }
  .embed-responsive-1by1-xs::before {
    padding-top: 100%; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #0077c1 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #00588e !important; }

.text-secondary {
  color: #5ac4be !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #3eada7 !important; }

.text-muted {
  color: #6c757d !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

/* Global variables and settings */
/* ordered list of all media queries (for mqpacker) ### INFO: empty comments are needed ### */
@media screen and (max-width: 767px) {
  /**/ }

@media screen and (min-width: 768px) {
  /**/ }

@media screen and (min-width: 992px) {
  /**/ }

@media screen and (min-width: 1200px) {
  /**/ }

@media screen and (min-width: 1400px) {
  /**/ }

/* colors */
/* fonts */
/*$font-fira-mono:        'FiraMono-Regular', sans-serif !default;*/
@font-face {
  font-family: "iconfont";
  src: url("/static/build/icons/font/iconfont.eot?#iefix") format("eot"), url("/static/build/icons/font/iconfont.woff") format("woff"), url("/static/build/icons/font/iconfont.ttf") format("ttf");
  font-weight: normal;
  font-style: normal; }

.icon {
  display: inline-block;
  font-family: "iconfont";
  speak: none;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0); }

.icon-lg {
  font-size: 1.3333333333333333em;
  line-height: 0.75em;
  vertical-align: -15%; }

.icon-2x {
  font-size: 2em; }

.icon-3x {
  font-size: 3em; }

.icon-4x {
  font-size: 4em; }

.icon-5x {
  font-size: 5em; }

.icon-fw {
  width: 1.2857142857142858em;
  text-align: center; }

.icon-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none; }

.icon-ul > li {
  position: relative; }

.icon-li {
  position: absolute;
  left: -2.14285714em;
  width: 2.14285714em;
  top: 0.14285714em;
  text-align: center; }

.icon-li.icon-lg {
  left: -1.85714286em; }

.icon-rotate-90 {
  transform: rotate(90deg); }

.icon-rotate-180 {
  transform: rotate(180deg); }

.icon-rotate-270 {
  transform: rotate(270deg); }

.icon-flip-horizontal {
  transform: scale(-1, 1); }

.icon-flip-vertical {
  transform: scale(1, -1); }

:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  filter: none; }

.icon-agenti:before {
  content: "\EA01"; }

.icon-ansicht:before {
  content: "\EA02"; }

.icon-arrow-down:before {
  content: "\EA03"; }

.icon-arrow-left:before {
  content: "\EA04"; }

.icon-arrow-right:before {
  content: "\EA05"; }

.icon-chat:before {
  content: "\EA06"; }

.icon-checkmark:before {
  content: "\EA07"; }

.icon-close:before {
  content: "\EA08"; }

.icon-compass:before {
  content: "\EA09"; }

.icon-download:before {
  content: "\EA0A"; }

.icon-elements:before {
  content: "\EA0B"; }

.icon-expand:before {
  content: "\EA0C"; }

.icon-extranet:before {
  content: "\EA0D"; }

.icon-facebook:before {
  content: "\EA0E"; }

.icon-fax:before {
  content: "\EA0F"; }

.icon-feather:before {
  content: "\EA10"; }

.icon-hexagon:before {
  content: "\EA11"; }

.icon-homepage:before {
  content: "\EA12"; }

.icon-instagram:before {
  content: "\EA13"; }

.icon-linkedin:before {
  content: "\EA14"; }

.icon-listview:before {
  content: "\EA15"; }

.icon-lock:before {
  content: "\EA16"; }

.icon-mail-round:before {
  content: "\EA17"; }

.icon-mail:before {
  content: "\EA18"; }

.icon-map:before {
  content: "\EA19"; }

.icon-minus:before {
  content: "\EA1A"; }

.icon-news:before {
  content: "\EA1B"; }

.icon-open:before {
  content: "\EA1C"; }

.icon-person:before {
  content: "\EA1D"; }

.icon-phone-round:before {
  content: "\EA1E"; }

.icon-phone:before {
  content: "\EA1F"; }

.icon-pinterest:before {
  content: "\EA20"; }

.icon-play:before {
  content: "\EA21"; }

.icon-poi:before {
  content: "\EA22"; }

.icon-quote:before {
  content: "\EA23"; }

.icon-scroll-down:before {
  content: "\EA24"; }

.icon-scroll-right:before {
  content: "\EA25"; }

.icon-search:before {
  content: "\EA26"; }

.icon-technogramm:before {
  content: "\EA27"; }

.icon-twitter:before {
  content: "\EA28"; }

.icon-vk:before {
  content: "\EA29"; }

.icon-world:before {
  content: "\EA2A"; }

.icon-xing:before {
  content: "\EA2B"; }

.icon-youtube:before {
  content: "\EA2C"; }

/* General base styling & reset (mostly plain element styling; very low specificity ) */
/*Base-Styling for HTML-Elements*/
a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none; }

th {
  font-family: "EncodeSans-Semibold", "Arial Black", sans-serif;
  font-weight: 400; }

textarea {
  resize: vertical; }

label {
  font-weight: normal;
  margin-bottom: 0; }

ul,
ol,
dl {
  margin-bottom: 0; }

dt {
  font-weight: normal; }

*::-moz-selection {
  color: #ffffff;
  background-color: #0077c1; }

*::selection {
  color: #ffffff;
  background-color: #0077c1; }

html {
  -ms-overflow-style: scrollbar;
  /*prevent scrollbar from overlapping content in IE*/ }

body {
  position: relative;
  overflow-x: hidden; }

body.has-cookie-bar {
  margin-bottom: 5rem; }
  @media screen and (max-width: 767px) {
    body.has-cookie-bar {
      margin-bottom: 9.375rem; } }

@media screen and (min-width: 768px) and (max-width: 991px) {
  body {
    width: calc(982rem/16);
    overflow-x: scroll; } }

@media screen and (max-width: 767px) {
  body {
    min-width: calc(320rem/16);
    max-width: 100%; } }

.container.container {
  max-width: calc(1470rem/16);
  width: 100%; }

.container-narrow {
  max-width: 80rem; }

.container-sm {
  max-width: 66.25rem; }

.container-xs {
  max-width: 60rem;
  margin: 0 auto; }

body {
  color: #282828;
  font-family: "EncodeSans-Light", Arial, sans-serif;
  font-size: 0.9375rem;
  line-height: 1.64706; }
  @media screen and (min-width: 768px) {
    body {
      font-size: 1.0625rem; } }

b,
strong,
.strong,
.font-default-bold {
  font-family: "EncodeSans-Semibold", "Arial Black", sans-serif;
  font-weight: 400; }

.font-default-italic {
  font-family: "EncodeSans-Light", Arial, sans-serif;
  font-weight: 400; }

/*.font-fira-mono {
    font-family: $font-fira-mono;
}*/
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-top: 0;
  display: block; }

h1, .h1 {
  font-size: 4.5rem;
  line-height: 1;
  font-family: "EncodeSans-Semibold", "Arial Black", sans-serif;
  font-weight: 400; }

h2, .h2 {
  font-size: 1.875rem;
  line-height: 1.21739;
  font-family: "EncodeSans-Semibold", "Arial Black", sans-serif;
  font-weight: 400; }
  @media screen and (min-width: 768px) {
    h2, .h2 {
      font-size: 2.875rem; } }

h3, .h3 {
  font-size: 1.5rem;
  font-family: "EncodeSans-Semibold", "Arial Black", sans-serif;
  font-weight: 400; }
  @media screen and (min-width: 768px) {
    h3, .h3 {
      font-size: 2rem; } }

h4, .h4 {
  font-size: 1.125rem; }
  @media screen and (min-width: 768px) {
    h4, .h4 {
      font-size: 1.375rem; } }

h5, .h5 {
  font-size: calc(18rem/16); }

h6, .h6 {
  font-size: 1em; }

/* Navbar */
.navbar {
  padding: 0;
  align-items: flex-end;
  z-index: 100;
  background: #fff;
  box-shadow: 0 0.125rem 0.1875rem rgba(0, 0, 0, 0.1); }

.navbar-brand {
  width: 3.75rem;
  margin: -1.5625rem 2.25rem -1.5rem -0.1875rem;
  z-index: 1;
  padding: 0; }
  @media screen and (min-width: 768px) {
    .navbar-brand {
      width: 6.25rem;
      margin: -4.0625rem 1.5625rem -4.0625rem -0.1875rem; } }
  @media screen and (min-width: 1200px) {
    .navbar-brand {
      width: 6.5625rem;
      margin: -3.75rem 2.25rem -3.5rem -0.1875rem; } }

.navbar-brand > img {
  max-width: 100%;
  height: auto; }

.navbar-nav .nav-link:hover {
  color: #5ac4be; }

/* navbar main */
.navbar-main {
  border-top: 0.0625rem solid #DCDCDC; }

@media screen and (min-width: 768px) {
  .navbar-main .nav-item {
    margin-top: calc(15rem / 16); } }

@media screen and (min-width: 768px) {
  .navbar-main .navbar-nav {
    margin: 0 -1.125rem; } }

.navbar-main .nav-item .nav-link {
  font-family: "EncodeSans-Semibold", "Arial Black", sans-serif;
  font-weight: 400;
  font-size: calc(16rem / 16);
  line-height: calc(21/18);
  padding: calc(20rem / 16); }
  @media screen and (min-width: 768px) {
    .navbar-main .nav-item .nav-link {
      padding: calc(10rem / 16) calc(12rem / 16) calc(24rem / 16); } }
  @media screen and (min-width: 1200px) {
    .navbar-main .nav-item .nav-link {
      padding: calc(10rem / 16) calc(18rem / 16) calc(24rem / 16);
      font-size: calc(18rem / 16); } }

/* navbar top */
.navbar-top {
  margin: 0 0 0 -0.5625rem;
  font-size: 0.8125rem; }
  @media screen and (min-width: 1200px) {
    .navbar-top {
      font-size: 1rem; } }

.navbar-top .nav-item {
  padding: 0.5rem 0; }

.navbar-top .nav-item .nav-link {
  font-family: "EncodeSans-Light", Arial, sans-serif;
  font-weight: 400; }

/* navbar search */
.navbar-main__search {
  flex-wrap: nowrap; }

.navbar-main__search .form-control {
  border: none;
  background: transparent;
  font-family: "EncodeSans-Semibold", "Arial Black", sans-serif;
  font-weight: 400;
  padding-right: 0.9375rem;
  font-size: 1rem;
  display: inline-block;
  vertical-align: middle; }
  @media screen and (min-width: 768px) {
    .navbar-main__search .form-control {
      width: auto; } }

.navbar-main__search__submit__icon {
  font-size: 1.25rem;
  vertical-align: -0.2em; }

/* navbar shortcuts*/
@media screen and (min-width: 1400px) {
  li + li .navbar-top__link {
    margin-left: 0.625rem; } }

.navbar-top__link__icon {
  vertical-align: -.05em;
  margin-right: 0.1875rem; }

.navbar-top__link__icon-flag {
  margin-right: 0.5rem; }

/* affix */
.navbar.is-transitioning {
  position: absolute;
  left: 0;
  right: 0; }

.navbar.is-affix {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0; }

@media screen and (max-width: 767px) {
  html.has-overflow-hidden, .has-overflow-hidden body {
    overflow: hidden; }
  .navbar-collapse {
    position: absolute;
    height: calc(100vh - 60px);
    background: #fff;
    color: #282828;
    left: 0;
    right: 0;
    top: 3.75rem;
    transform: translateX(-100%);
    transition: transform 250ms ease-in-out;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 6.25rem; }
  .is-open.navbar-main {
    background: #fff; }
  .is-open .navbar-collapse {
    transform: translateX(0); }
  .is-open .navbar__icon-link {
    color: #282828; }
  .navbar-main {
    flex-direction: row;
    justify-content: start; }
  .navbar-nav {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0; }
  .navbar-nav > li {
    border-bottom: 0.0625rem solid #DCDCDC; }
  .navbar-nav li a {
    display: block;
    position: relative;
    padding: 0.625rem 0.3125rem; }
  .nav-item__dropdown__subnav li a {
    padding: 0.25rem 0.9375rem; }
  .navbar-nav li.is-open > a {
    color: #5ac4be; }
  /* toggle button */
  .navbar-toggler {
    width: 3.75rem;
    height: 3.75rem;
    text-align: center;
    font-size: 0;
    position: relative; }
  .navbar-toggler__bar {
    display: inline-block;
    width: 1.5625rem;
    height: 0.125rem;
    background: #3B3B39;
    transition: transform 250ms ease-in-out, opacity 250ms ease-in-out, color 250ms ease-in-out; }
  .navbar-main--white .navbar-toggler__bar {
    background: #3B3B39; }
  .navbar-toggler__bar + .navbar-toggler__bar {
    margin-top: 0.3125rem; }
  .is-open .navbar-toggler__bar {
    background: #3B3B39; }
  .is-open .navbar-toggler__bar:nth-child(2) {
    opacity: 0; }
  .is-open .navbar-toggler__bar:nth-child(1) {
    transform: rotate(-45deg) translateX(-0.25rem) translateY(0.25rem); }
  .is-open .navbar-toggler__bar:nth-child(3) {
    transform: rotate(45deg) translateX(-0.375rem) translateY(-0.3125rem); }
  .nav-item__toggle {
    position: absolute;
    padding: 0.9375rem;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1rem;
    transition: transform 250ms ease-in-out; }
  .nav-item__dropdown li.is-open > a .nav-item__toggle {
    transform: translateY(-50%) rotate(-180deg); }
  .nav-item__dropdown {
    transform: translateX(100%);
    transition: transform 200ms ease-in-out;
    z-index: 1;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    background-image: url(/static/img/flexigons/flexigon-white-square.svg);
    background-repeat: no-repeat;
    background-position: -105px 100%;
    background-size: 500px 500px; }
  .is-open > .nav-item__dropdown {
    transform: translateX(0); }
  .is-open > .nav-item__dropdown__subnav {
    display: block; }
  .nav-item__dropdown__back {
    width: 100%;
    padding: 0.3125rem 0;
    font-size: 1rem;
    font-family: "EncodeSans-Semibold", "Arial Black", sans-serif;
    font-weight: 400; }
  .nav-item__dropdown__back__icon {
    margin-right: 0.625rem;
    font-size: 0.75rem; }
  /* navbar brand */
  .navbar-brand {
    padding-left: 0.9375rem;
    max-width: 5rem; }
  .navbar-nav--sm {
    margin-top: 0.9375rem; }
  .navbar-nav--sm li {
    border: 0; }
  .navbar-main .navbar-nav--sm .nav-item > a {
    font-size: 0.75rem;
    padding: 0.5rem 1.25rem; }
  .navbar-main__search {
    padding: 0 0.5rem; } }

.breadcrumb {
  padding: 0.9375rem 1rem 0.625rem 0.375rem; }
  @media screen and (min-width: 768px) {
    .breadcrumb {
      padding: 2.5rem 1rem 1.75rem 0.375rem; } }
  @media screen and (min-width: 1200px) {
    .breadcrumb {
      padding: 1.375rem 1rem 1.75rem 0.375rem; } }

.breadcrumb-item {
  color: #0077c1;
  font-size: 0.875rem;
  line-height: 1.14286;
  position: relative; }

.breadcrumb-item:not(:last-child) {
  margin-right: 0.625rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.875rem; }

.breadcrumb-item + .breadcrumb-item::before {
  content: '';
  width: 0.1875rem;
  height: 0.1875rem;
  background: #999;
  display: inline-block;
  padding: 0;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%); }

/* Generic helper classes (mostly layout) */
.row.row--gutter-width-0 {
  margin-left: 0;
  margin-right: 0; }

.row--gutter-width-0 > .col,
.row--gutter-width-0 > [class^="col"],
.row--gutter-width-0 > [class*=" col"] {
  padding-left: 0;
  padding-right: 0; }

.row.row--gutter-width-4 {
  margin-left: -0.125rem;
  margin-right: -0.125rem; }

.row--gutter-width-4 > .col,
.row--gutter-width-4 > [class^="col"],
.row--gutter-width-4 > [class*=" col"] {
  padding-left: 0.125rem;
  padding-right: 0.125rem; }

.row.row--gutter-width-12 {
  margin-left: -0.375rem;
  margin-right: -0.375rem; }

.row--gutter-width-12 > .col,
.row--gutter-width-12 > [class^="col"],
.row--gutter-width-12 > [class*=" col"] {
  padding-left: 0.375rem;
  padding-right: 0.375rem; }

.row.row--gutter-width-16 {
  margin-left: -0.5rem;
  margin-right: -0.5rem; }

.row--gutter-width-16 > .col,
.row--gutter-width-16 > [class^="col"],
.row--gutter-width-16 > [class*=" col"] {
  padding-left: 0.5rem;
  padding-right: 0.5rem; }

.row.row--gutter-width-20 {
  margin-left: -10px;
  margin-right: -10px; }

.row--gutter-width-20 > .col,
.row--gutter-width-20 > [class^="col"],
.row--gutter-width-20 > [class*=" col"] {
  padding-left: 10px;
  padding-right: 10px; }

@media screen and (min-width: 1200px) {
  .row.row--gutter-width-36 {
    margin-left: -18px;
    margin-right: -18px; }
  .row--gutter-width-36 > .col,
  .row--gutter-width-36 > [class^="col"],
  .row--gutter-width-36 > [class*=" col"] {
    padding-left: 18px;
    padding-right: 18px; }
  .row.row--gutter-width-60 {
    margin-left: -30px;
    margin-right: -30px; }
  .row--gutter-width-60 > .col,
  .row--gutter-width-60 > [class^="col"],
  .row--gutter-width-60 > [class*=" col"] {
    padding-left: 30px;
    padding-right: 30px; }
  .row.row--gutter-width-120 {
    margin-left: -60px;
    margin-right: -60px; }
  .row--gutter-width-120 > .col,
  .row--gutter-width-120 > [class^="col"],
  .row--gutter-width-120 > [class*=" col"] {
    padding-left: 60px;
    padding-right: 60px; } }

/* vertical gutter */
.row--vertical-gutter-4 {
  margin-top: -0.25rem; }

.row--vertical-gutter-4 > .col,
.row--vertical-gutter-4 > [class*='col-'] {
  margin-top: 0.25rem; }

.row--vertical-gutter-6 {
  margin-top: -0.375rem; }

.row--vertical-gutter-6 > .col,
.row--vertical-gutter-6 > [class*='col-'] {
  margin-top: 0.375rem; }

.row--vertical-gutter-12 {
  margin-top: -0.75rem; }

.row--vertical-gutter-12 > .col,
.row--vertical-gutter-12 > [class*='col-'] {
  margin-top: 0.75rem; }

.row--vertical-gutter-24 {
  margin-top: -1.5rem; }

.row--vertical-gutter-24 > .col,
.row--vertical-gutter-24 > [class*='col-'] {
  margin-top: 1.5rem; }

.row--vertical-gutter-30 {
  margin-top: -1.875rem; }

.row--vertical-gutter-30 > .col,
.row--vertical-gutter-30 > [class*='col-'] {
  margin-top: 1.875rem; }

@media screen and (min-width: 768px) {
  .row--vertical-gutter-70-lg {
    margin-top: -4.375rem; }
  .row--vertical-gutter-70-lg > .col,
  .row--vertical-gutter-70-lg > [class*='col-'] {
    margin-top: 4.375rem; } }

/* Components */
.btn {
  position: relative;
  font-family: "EncodeSans-Semibold", "Arial Black", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.15;
  padding: 0.875rem 1.125rem 0.8125rem;
  text-transform: uppercase; }
  @media screen and (min-width: 768px) {
    .btn {
      font-size: 1.125rem;
      padding: 1rem 2rem 0.9375rem; } }

.btn-sm {
  display: inline-block;
  padding: .5rem !important;
  font-family: "EncodeSans-Semibold", "Arial Black", sans-serif;
  font-size: 0.9375rem !important;
  text-transform: uppercase; }

.btn-secondary {
  margin-right: 0.9375rem;
  color: #fff; }

.btn-secondary:after {
  content: '';
  position: absolute;
  right: -0.0625rem;
  bottom: -0.0625rem;
  top: -0.0625rem;
  transform: translateX(0.9375rem);
  width: 0.75rem;
  background: #0077c1; }

.btn-grey-light {
  background: #f4f4f4;
  color: #282828; }

.btn-grey-light:hover,
.btn-grey-light:focus {
  background: #DCDCDC; }

.btn-no-styling,
.btn.btn-no-styling {
  border: none;
  background: none;
  text-align: left;
  padding: 0;
  color: inherit;
  font-family: inherit;
  font-weight: inherit;
  cursor: pointer; }

/* button with icon & text */
.btn.btn-with-icon {
  padding-left: 3.75rem; }

.btn-with-icon .btn__icon {
  position: absolute;
  left: 0.9375rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2rem; }

/* disabled buttons */
.btn.disabled {
  background-color: #999999;
  border-color: #999999; }

.btn-secondary.disabled:after {
  background-color: #696969; }

.custom-checkbox {
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1.46667;
  font-family: "EncodeSans-Light", Arial, sans-serif;
  font-weight: 400; }

.custom-checkbox + .custom-checkbox {
  margin-top: 0.75rem; }

.custom-checkbox label {
  cursor: pointer; }

.custom-checkbox:before,
.custom-checkbox:after {
  content: " ";
  display: table; }

.custom-checkbox:after {
  clear: both; }

.custom-checkbox__input {
  position: absolute;
  width: 0.0625rem;
  height: 0.0625rem;
  margin: -0.0625rem;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.custom-checkbox__box {
  position: relative;
  float: left;
  margin-right: 0.75rem;
  border: 1px solid #DCDCDC;
  background: #DCDCDC;
  width: 1.375rem;
  height: 1.375rem;
  margin-top: -0.0625rem; }

.custom-checkbox__box:before {
  position: absolute;
  top: 50%;
  right: -0.3125rem;
  transform: translateY(-50%);
  font-size: 1.125rem;
  color: #5ac4be;
  visibility: hidden; }

.custom-checkbox__input:checked ~ .custom-checkbox__box:before {
  visibility: visible; }

.custom-checkbox__input:not(:checked):focus ~ .custom-checkbox__box {
  border-color: #5ac4be; }

.has-error .custom-checkbox__box {
  border-color: #dc3545; }

.custom-checkbox__text {
  display: block;
  overflow: hidden; }

.custom-checkbox__text__icon {
  font-size: 1.25rem;
  vertical-align: -.2em;
  margin-left: 0.125rem; }

.custom-checkbox--white .custom-checkbox__box {
  background: #fff;
  border-color: #fff;
  box-shadow: 0 -1px 0 0 #DCDCDC; }

.content-block + .content-block,
.pimcore_area_content + .pimcore_area_content > .content-block {
  margin-top: calc(30rem/16); }

@media screen and (min-width: 768px) {
  .content-block + .content-block,
  .pimcore_area_content + .pimcore_area_content > .content-block {
    margin-top: calc(40rem/16); } }

.slide {
  padding-top: 1.875rem;
  padding-bottom: 1.875rem; }

@media screen and (min-width: 768px) {
  .slide {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem; } }

.slide-sm {
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem; }

.slide__head {
  padding-bottom: 1.875rem; }
  @media screen and (min-width: 768px) {
    .slide__head {
      padding-bottom: 3.75rem; } }

.slide__head h2 {
  color: #C09B57; }

.slide--no-top-padding.slide--no-top-padding {
  padding-top: 0; }

.slide.slide--with-rgb-flexigon {
  position: relative; }

@media screen and (min-width: 768px) {
  .slide.slide--with-rgb-flexigon:before {
    content: '';
    pointer-events: none;
    display: block;
    position: absolute;
    left: -5rem;
    bottom: -13rem;
    width: 900px;
    height: 900px;
    z-index: -1;
    background: url(/static/img/flexigons/flexigon-rgb-square.svg) -90px 100% no-repeat; } }

.page-heading {
  padding: 1.25rem 0; }

.wysiwyg > p,
.wysiwyg > ol,
.wysiwyg > ul,
.wysiwyg > table {
  margin-bottom: 0.6em; }

.wysiwyg > p:last-child,
.wysiwyg > ol:last-child,
.wysiwyg > ul:last-child,
.wysiwyg > table:last-child {
  margin-bottom: 0; }

.wysiwyg img {
  max-width: 100%;
  height: auto !important;
  /*override inline style from wysiwyg*/ }

.wysiwyg a:not(.btn) {
  color: #0077c1;
  font-family: "EncodeSans-Semibold", "Arial Black", sans-serif;
  font-weight: 400; }

.wysiwyg a:not(.btn):hover,
.wysiwyg a:not(.btn):focus,
.wysiwyg a:not(.btn):active {
  color: #0077c1;
  text-decoration: underline; }

.wysiwyg ol,
.wysiwyg ul {
  padding: 0; }

.wysiwyg ol li,
.wysiwyg ul li {
  position: relative;
  list-style: none;
  padding-left: 1.75rem; }
  @media screen and (min-width: 768px) {
    .wysiwyg ol li,
    .wysiwyg ul li {
      padding-left: 2.1875rem; } }

.wysiwyg ul li:before {
  content: '';
  display: block;
  position: absolute;
  left: .625rem;
  top: .375rem;
  width: .5rem;
  height: .625rem;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDE0IDI1IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zOnNlcmlmPSJodHRwOi8vd3d3LnNlcmlmLmNvbS8iIHN0eWxlPSJmaWxsLXJ1bGU6ZXZlbm9kZDtjbGlwLXJ1bGU6ZXZlbm9kZDtzdHJva2UtbGluZWpvaW46cm91bmQ7c3Ryb2tlLW1pdGVybGltaXQ6MS40MTQyMTsiPgogICAgPGcgdHJhbnNmb3JtPSJtYXRyaXgoMSwwLDAsMSwtNjkyLjEyNSwtMTQ0MS40KSI+CiAgICAgICAgPGcgdHJhbnNmb3JtPSJtYXRyaXgoNC4xNjY2NywwLDAsNC4xNjY2NywwLDApIj4KICAgICAgICAgICAgPHBhdGggZD0iTTE2OC4wMTIsMzQ2LjI1OEMxNjcuODk4LDM0Ni4wNTkgMTY3LjY4NywzNDUuOTM3IDE2Ny40NTgsMzQ1LjkzN0wxNjYuMTEzLDM0NS45MzdMMTY2LjExLDM0NS45NDFMMTY3LjYwMiwzNDguNTM4QzE2Ny43MTYsMzQ4LjczNyAxNjcuNzE2LDM0OC45ODIgMTY3LjYwMiwzNDkuMTgxTDE2Ni4xMSwzNTEuNzc4TDE2Ni4xMTMsMzUxLjc4M0wxNjcuNDU4LDM1MS43ODNDMTY3LjY4NywzNTEuNzgzIDE2Ny44OTgsMzUxLjY2IDE2OC4wMTIsMzUxLjQ2MkwxNjkuMzIyLDM0OS4xODFDMTY5LjQzNiwzNDguOTgyIDE2OS40MzYsMzQ4LjczNyAxNjkuMzIyLDM0OC41MzhMMTY4LjAxMiwzNDYuMjU4WiIgc3R5bGU9ImZpbGw6cmdiKDY0LDYzLDY1KTtmaWxsLXJ1bGU6bm9uemVybzsiLz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPgo=");
  background-repeat: no-repeat;
  background-size: contain; }
  @media screen and (min-width: 768px) {
    .wysiwyg ul li:before {
      top: .5rem;
      left: 1rem; } }

.wysiwyg ol li + li,
.wysiwyg ol li ol,
.wysiwyg ul li + li,
.wysiwyg ul li ul {
  margin-top: 0.5rem; }
  @media screen and (min-width: 768px) {
    .wysiwyg ol li + li,
    .wysiwyg ol li ol,
    .wysiwyg ul li + li,
    .wysiwyg ul li ul {
      margin-top: 0.9375rem; } }

.wysiwyg ol {
  counter-reset: item; }

.wysiwyg ol li {
  display: block; }

.wysiwyg ol li:before {
  position: absolute;
  left: .5rem;
  top: 0;
  content: counters(item, ".") " ";
  counter-increment: item; }
  @media screen and (min-width: 768px) {
    .wysiwyg ol li:before {
      left: 1rem; } }

.wysiwyg ol li li:before {
  left: 0rem; }
  @media screen and (min-width: 768px) {
    .wysiwyg ol li li:before {
      left: .5rem; } }

.wysiwyg ol li li li:before {
  left: -.5rem; }
  @media screen and (min-width: 768px) {
    .wysiwyg ol li li li:before {
      left: 0rem; } }

/* wysiwyg text color white */
.text-white .wysiwyg ul li:before {
  background: #fff; }

.text-white .wysiwyg a:not(.btn) {
  text-decoration: underline;
  color: #fff; }

.text-white .wysiwyg a:not(.btn):hover,
.text-white .wysiwyg a:not(.btn):focus,
.text-white .wysiwyg a:not(.btn):active {
  color: #fff;
  text-decoration: none; }

.hero {
  position: relative; }

.hero .slick-slider {
  overflow: hidden; }

.hero__slide {
  position: relative;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 58%;
  overflow: hidden; }
  @media screen and (min-width: 768px) {
    .hero__slide {
      padding-top: 0;
      min-height: 42.0625rem;
      min-height: 40.05952vw; } }

@media screen and (min-width: 768px) {
  .hero--sm-with-gradient:before,
  .hero--sm-with-gradient-full:before,
  .hero__slide:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: radial-gradient(circle, transparent 0%, transparent 18.65%, #000000 100%);
    z-index: 1;
    opacity: .6; }
  .hero--sm-with-gradient-full:before {
    background: #000;
    opacity: .45; } }

.hero__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: auto; }
  @media screen and (min-width: 768px) {
    .hero__bg {
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 100%;
      width: auto; } }

.hero__content {
  position: relative;
  padding: 1rem 0 2.5rem;
  background: #3B3B39;
  z-index: 5; }
  @media screen and (min-width: 768px) {
    .hero__content {
      padding: 1rem 3.75rem;
      background: transparent; } }

@media screen and (max-width: 767px) {
  .hero__content--singleitem {
    padding: 1.5rem 0; } }

.hero__title {
  font-size: 2rem;
  line-height: 1; }
  @media screen and (min-width: 768px) {
    .hero__title {
      font-size: 3.125rem; } }
  @media screen and (min-width: 1200px) {
    .hero__title {
      font-size: 4.5rem; } }

.hero__text {
  margin-top: 0.9375rem; }
  @media screen and (min-width: 768px) {
    .hero__text {
      font-size: 1.125rem;
      margin-top: 2rem; } }

.hero__badge {
  font-size: 0.9375rem;
  line-height: 1.2;
  padding: 0.5rem 1.125rem; }

.hero__scroll {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(50%) rotate(90deg);
  color: #fff;
  font-size: 3.125rem; }

.hero__scroll:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  font-size: 2.75rem;
  line-height: 1px;
  color: #5ac4be;
  transition: color 200ms ease-in-out; }

.hero__scroll__icon {
  position: absolute;
  top: 1.55rem;
  left: 0.1875rem;
  font-size: 1.875rem;
  line-height: 1px;
  color: #fff;
  z-index: 1; }

.hero__scroll:hover {
  color: #fff; }

.hero__scroll:hover:after {
  color: #0077c1; }

@media screen and (min-width: 768px) {
  .hero__content--top {
    position: absolute;
    left: 0;
    right: 0;
    top: 20%; } }

@media screen and (min-width: 1200px) {
  .hero__content--top {
    top: 30%; } }

@media screen and (min-width: 768px) {
  .hero__content--middle {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%); } }

.hero__arrow {
  position: absolute;
  bottom: 12%;
  left: 50%;
  transform: translateX(-100%);
  font-size: 0.8125rem;
  color: #fff;
  padding: 0 2.5rem;
  z-index: 5; }

.hero__arrow--next {
  transform: translateX(0); }

.hero__arrow__icon {
  font-size: 1.875rem;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%); }

.hero__arrow--next .hero__arrow__icon {
  left: auto;
  right: 0; }

/* small hero */
.hero--sm .hero__title {
  font-size: 1.75rem;
  margin-bottom: 0.125rem; }
  @media screen and (min-width: 768px) {
    .hero--sm .hero__title {
      font-size: 2.875rem;
      line-height: 1.21739; } }

.hero--sm .hero__text {
  font-size: 1.25rem;
  margin-top: 0; }

@media screen and (max-width: 767px) {
  .hero .slick-arrow {
    top: 20%; }
  .hero .slick-dots {
    left: 0;
    right: 0;
    transform: none;
    display: flex;
    padding: 0;
    margin-top: 58%; }
  .hero .slick-dots li {
    align-items: flex-end;
    display: flex;
    flex: 1 1 100%; }
  .hero .slick-dots button {
    width: 100%;
    bottom: 0.3125rem; } }

.contact-block__text {
  font-size: 1.125rem; }
  @media screen and (min-width: 768px) {
    .contact-block__text {
      margin-left: -18.125rem; } }

@media screen and (min-width: 768px) {
  .contact-block__bottom {
    margin-top: -3.625rem; } }

.contact-block__text__title {
  padding: 1.25rem 0;
  font-size: 1.5rem;
  line-height: 1.23333; }
  @media screen and (min-width: 768px) {
    .contact-block__text__title {
      font-size: 1.625rem; } }
  @media screen and (min-width: 1400px) {
    .contact-block__text__title {
      font-size: 1.875rem; } }

.contact-block__decorator {
  position: relative; }

.contact-block__decorator__flag {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -0.375rem;
  z-index: 1; }

.contact-info {
  font-size: 0.875rem; }

.contact-info__title {
  font-size: 1.375rem;
  border-bottom: 0.0625rem solid #C6C6C6;
  padding-bottom: 0.375rem;
  margin-bottom: 1.125rem; }

.contact-info__data {
  border-top: 0.0625rem solid #C6C6C6;
  border-bottom: 1px solid #C6C6C6;
  margin: 0.9375rem 0;
  padding: 0.5rem 0; }

.contact-info__data__link-icon {
  vertical-align: -.1em;
  margin-right: 0.5rem; }

/* base form styles in boostrap/_custom.scss */
.form-control {
  font-family: "EncodeSans-Light", Arial, sans-serif;
  font-weight: 400;
  padding: 0.75rem 0.625rem;
  font-size: 1rem;
  line-height: 1.22222; }
  @media screen and (min-width: 768px) {
    .form-control {
      padding: 0.875rem 1rem; } }

.form-control:focus {
  box-shadow: none;
  color: #5ac4be; }

.form-group {
  position: relative;
  margin-bottom: 1.25rem; }

.form-group--sm {
  margin-bottom: 0.75rem; }

.form-control-btn {
  position: absolute;
  right: 0;
  top: 0.5rem;
  font-size: 1.125rem;
  background: #fff;
  padding: 0 0.125rem 0 0.3125rem; }
  @media screen and (min-width: 768px) {
    .form-control-btn {
      font-size: 1.25rem; } }

/* form colors */
.form-control--light {
  background: transparent;
  border: 0;
  border-bottom: 0.0625rem solid #999999;
  padding: 0.625rem 0.125rem; }

.form-control--white {
  background: #fff;
  box-shadow: 0 -0.0625rem 0 0 #DCDCDC;
  color: #5ac4be; }

.required-symbol {
  font-size: 0.875rem;
  line-height: 1;
  vertical-align: 0.2em;
  color: #0077c1; }

.input-wrapper {
  position: relative; }

.input-wrapper .required-symbol {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background: #DCDCDC;
  width: 1rem;
  text-align: center;
  padding: 0.3125rem 0; }

.has-error .form-control,
.has-error .custom-checkbox__box,
.has-error .custom-radio__box {
  border-color: #CC0000; }

.has-error .input-wrapper .required-symbol {
  color: #fff;
  background: #CC0000; }

.has-success .form-control,
.has-success .custom-checkbox__box,
.has-success .custom-radio__box {
  border-color: #24b377; }

.has-success .input-wrapper .required-symbol {
  color: #fff;
  background: #24b377; }

.filter-title {
  border-bottom: 0.0625rem solid #DCDCDC;
  padding-bottom: 0.125rem; }

.location-item {
  font-size: 0.875rem;
  line-height: 1.28571;
  padding: 0.9375rem; }
  @media screen and (min-width: 768px) {
    .location-item {
      padding: 1.875rem; } }

.location-item + .location-item {
  margin-top: 0.3125rem; }

.location-item__title {
  font-size: 0.875rem;
  line-height: 1;
  margin-bottom: 0.3125rem; }

.location-item__address {
  font-size: 0.875rem;
  line-height: 1.57143; }

.location-item__contact + .location-item__contact {
  margin-top: 0.5625rem; }

.location-item__contact__icon {
  margin-right: 0.5rem;
  vertical-align: -0.2em; }

/* location collapse groups */
.location-item-group__body {
  margin-bottom: 1.5625rem; }

.location-item-group__title {
  font-size: 1.125rem; }

.location-item-group__title > a {
  position: relative;
  padding: 1.25rem 1.875rem 1.25rem 0;
  border-bottom: 2px solid #DCDCDC; }

.location-item-group__title__icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.75rem; }

/*todo*/
/*.overlay-box:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,.2);
}*/
.overlay-box {
  position: fixed;
  right: 50%;
  margin-right: -45rem;
  top: 0;
  border-top: 0.3125rem solid #5ac4be;
  z-index: 1;
  width: 25rem;
  padding: 2.1875rem 1.875rem;
  font-size: 0.9375rem;
  transform: translateY(-100%);
  opacity: 0;
  transition: transform 250ms ease-in-out, opacity 250ms ease-in-out; }

.overlay-box__close {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0.9375rem; }

.overlay-box.is-visible {
  transform: translateY(0);
  opacity: 1; }

.navbar.is-transitioning .overlay-box {
  opacity: 0; }

/* slider */
/* teaser */
.news-teaser {
  font-size: 0.9375rem;
  line-height: 1.6; }

.news-teaser__head {
  position: relative;
  margin-bottom: 0.75rem; }

.news-teaser__head:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  opacity: .6;
  transition: opacity 300ms ease-in-out; }

.news-teaser:hover .news-teaser__head:after {
  opacity: 0; }

.news-teaser__head__content {
  z-index: 1;
  padding: 0.9375rem 3.125rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0; }
  @media screen and (max-width: 1199px) {
    .news-teaser__head__content {
      padding: 0.3125rem 0 0.3125rem 2.5rem; } }

.news-teaser__head__content__title {
  font-size: 1.375rem;
  line-height: 1.27273; }
  @media (min-width: 768px) and (max-width: 1199px) {
    .news-teaser__head__content__title {
      font-size: 1.125rem; } }
  @media screen and (max-width: 767px) {
    .news-teaser__head__content__title {
      font-size: 1.375rem; } }

.news-teaser__additional-info li {
  display: inline-block;
  position: relative; }

.news-teaser__additional-info li:not(:last-child) {
  padding-right: 0.9375rem; }

.news-teaser__additional-info li:not(:last-child):before {
  content: '';
  position: absolute;
  right: 0.25rem;
  top: 50%;
  transform: translateY(-50%);
  width: 0.1875rem;
  height: 0.1875rem;
  border-radius: 50%;
  background: #3B3B39; }

/* Utility & Helper Classes (mostly atomic classes; high specificity; eg: mt10; fz20)*/
/*Margin Helper*/
/*Padding Helper*/
/*Text Helper*/
.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

/*Text Color Helper*/
.text-primary-hover:hover, a.text-primary {
  color: #0077c1 !important; }

a.text-primary:hover {
  color: #00588e !important; }

.text-secondary-hover:hover, .text-secondary-hover:focus {
  color: #5ac4be; }

.text-white {
  color: #ffffff; }

.text-color-default {
  color: #282828; }

.text-orange {
  color: #FF7100; }

.text-brown {
  color: #A66E00; }

.text-grey-dark {
  color: #696969; }

.text-gold {
  color: #C09B57; }

.text-poi-1 {
  color: #0077c1; }

.text-poi-2 {
  color: #FF7100; }

.text-poi-3 {
  color: #5ac4be; }

.text-poi-4 {
  color: #A66E00; }

.text-poi-5 {
  color: #5A3EB9; }

.text-poi-6 {
  color: #CC0000; }

.text-poi-7 {
  color: #056383; }

.text-poi-8 {
  color: #D16B00; }

.text-poi-9 {
  color: #5BA7C1; }

.text-poi-10 {
  color: #884600; }

/*Background Color Helper*/
.bg-default {
  background: #3B3B39;
  color: #fff; }

.bg-primary {
  color: #fff; }

.bg-secondary {
  color: #fff; }

.bg-gradient {
  background: linear-gradient(180deg, #EEEEEE 0%, rgba(238, 238, 238, 0) 100%); }

.bg-grey {
  background: #DCDCDC; }

.bg-grey-light {
  background: #f4f4f4; }

.isClickable {
  cursor: pointer; }

.link-with-icon__icon {
  font-size: 2.5rem;
  line-height: 1.03; }
  @media screen and (min-width: 768px) {
    .link-with-icon__icon {
      font-size: 3.125rem; } }

.content-box {
  padding: 0.9375rem;
  font-size: 0.75rem; }
  @media screen and (min-width: 768px) {
    .content-box {
      padding: 2.8125rem 3.625rem; } }

.thumb-slider__thumbs__item:nth-child(3) ~ .thumb-slider__thumbs__item,
.thumb-slider__main__item + .thumb-slider__main__item,
.teaser-slider__item + .teaser-slider__item,
.slider_item + .slider_item {
  display: none; }
