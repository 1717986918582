@media screen and (max-width: 767px) {
    html.has-overflow-hidden, .has-overflow-hidden body {
        overflow: hidden;
    }
    .navbar-collapse {
        position: absolute;
        height: calc(100vh - 60px);
        background: #fff;
        color: $color-default;
        left: 0;
        right: 0;
        top: rem(60);
        transform: translateX(-100%);
        transition: transform 250ms ease-in-out;
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling:touch;
        padding-bottom: rem(100);
    }
    .is-open.navbar-main {
        background: #fff;
    }
    .is-open .navbar-collapse {
        transform: translateX(0);
    }
    .is-open .navbar__icon-link {
        color: $color-default;
    }
    .navbar-main {
        flex-direction: row;
        justify-content: start;
    }
    .navbar-nav {
        display: block;
        width: 100%;
        margin: 0;
        padding: 0;
    }
    .navbar-nav > li {
        border-bottom: rem(1) solid $color-grey;
    }
    .navbar-nav li a {
        display: block;
        position: relative;
        padding: rem(10) rem(5);
    }
    .nav-item__dropdown__subnav li a {
        padding: rem(4) rem(15);
    }

    .navbar-nav li.is-open > a {
        color: $secondary;
    }

    /* toggle button */
    .navbar-toggler {
        width: rem(60);
        height: rem(60);
        text-align: center;
        font-size: 0;
        position: relative;
    }
    .navbar-toggler__bar {
        display: inline-block;
        width: rem(25);
        height: rem(2);
        background: $color-bg-default;
        transition: transform 250ms ease-in-out, opacity 250ms ease-in-out, color 250ms ease-in-out;
    }
    .navbar-main--white .navbar-toggler__bar {
        background: $color-bg-default;
    }
    .navbar-toggler__bar + .navbar-toggler__bar {
        margin-top: rem(5);
    }

    .is-open .navbar-toggler__bar{
        background: $color-bg-default;
    }
    .is-open .navbar-toggler__bar:nth-child(2) {
        opacity: 0;
    }
    .is-open .navbar-toggler__bar:nth-child(1) {
        transform: rotate(-45deg) translateX(rem(-4)) translateY(rem(4));
    }
    .is-open .navbar-toggler__bar:nth-child(3) {
        transform: rotate(45deg) translateX(rem(-6)) translateY(rem(-5));
    }

    .nav-item__toggle {
        position: absolute;
        padding: rem(15);
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        font-size: rem(16);
        transition: transform 250ms ease-in-out;
    }
    .nav-item__dropdown li.is-open > a .nav-item__toggle {
        transform: translateY(-50%) rotate(-180deg);
    }

    .nav-item__dropdown {
        transform: translateX(100%);
        transition: transform 200ms ease-in-out;
        z-index: 1;
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling:touch;
        background-image: url(/static/img/flexigons/flexigon-white-square.svg);
        background-repeat: no-repeat;
        background-position: -105px 100%;
        background-size: 500px 500px;
    }
    .is-open > .nav-item__dropdown {
        transform: translateX(0);
    }
    .is-open > .nav-item__dropdown__subnav {
        display: block;
    }

    .nav-item__dropdown__back {
        width: 100%;
        padding: rem(5) 0;
        font-size: rem(16);
        font-family: $font-default-bold;
        font-weight: 400;
    }
    .nav-item__dropdown__back__icon {
        margin-right: rem(10);
        font-size: rem(12);
    }

    /* navbar brand */
    .navbar-brand {
        padding-left: rem(15);
        max-width: rem(80);
    }

    .navbar-nav--sm {
        margin-top: rem(15);
    }
    .navbar-nav--sm li {
        border: 0;
    }
    .navbar-main .navbar-nav--sm .nav-item > a {
        font-size: rem(12);
        padding: rem(8) rem(20);
    }
    .navbar-main__search {
        padding: 0 rem(8);
    }
}