.contact-block__text {
    font-size: rem(18);

    @media screen and (min-width: 768px) {
        margin-left: rem(-290);
    }
}
.contact-block__bottom {
    @media screen and (min-width: 768px){
        margin-top: rem(-58);
    }
}
.contact-block__text__title {
  padding: rem(20) 0;
  font-size: rem(24);
  line-height: (37/30);

    @media screen and (min-width: 768px) {
        font-size: rem(26);
    }

    @media screen and (min-width: 1400px) {
        font-size: rem(30);
    }
}
.contact-block__decorator {
  position: relative;
}
.contact-block__decorator__flag {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: rem(-6);
  z-index: 1;
}

.contact-info {
    font-size: rem(14);
}
.contact-info__title {
    font-size: rem(22);
    border-bottom: rem(1) solid $color-grey-medium;
    padding-bottom: rem(6);
    margin-bottom: rem(18);
}
.contact-info__data {
    border-top: rem(1) solid $color-grey-medium;
    border-bottom: 1px solid $color-grey-medium;
    margin: rem(15) 0;
    padding: rem(8) 0;
}
.contact-info__data__link-icon {
    vertical-align: -.1em;
    margin-right: rem(8);
}